/**
 * 数组转树型数组
 * @param {array} list 数组
 * @returns
 */
export function listToTree(list) {
  list.forEach((item1) => {
    let parentId = item1.parentId;
    if (parentId !== 0) {
      list.forEach((item2) => {
        if (item2.id == parentId) {
          if (!item2.children) {
            item2.children = [];
          }
          item2.children.push(item1);
        }
      });
    }
  });
  list = list.filter((item) => item.parentId === 0);
  return list;
}
/**
 * 数组转树型数组
 * @param {array} list 数组
 * @returns
 */
export function listToTrees(list) {
  let json = []
  list.forEach((item1) => {
    item1.isChecked = false
    let parentId = item1.parentId;
    json.push(item1.id)
    if (parentId !== 0) {
      list.forEach((item2) => {
        item2.isChecked = false
        if (item2.id == parentId) {
          json.push(item2.id)
          if (!item2.children) {
            item2.children = [];
          } else {
            item2.children.forEach((item3) => {
              item3.isChecked = false
            })
          }
          item2.children.push(item1);
        }
      });
    }
  });
  list = list.filter((item) => item.parentId === 0);
  let obj = {
    data: list,
    ext: json
  }
  return obj;
}

/**
 * 数组转树型数组
 * @param {array} list 数组
 * @returns
 */
export function dataToTree(list, isChecked = false) {
  list.forEach((item1) => {
    let educationalInfos = item1.educationalInfos;
    item1.typeName = "学生信息";
    item1.isChecked = isChecked
    item1.isOff = false
    if (educationalInfos.length) {
      educationalInfos.forEach((item2) => {
        item2.typeName = "学历信息";
        item2.isChecked = isChecked
        item2.isOff = false
        let orderInfos = item2.orderInfos;
        if (orderInfos.length) {
          orderInfos.forEach((item3) => {
            item3.typeName = "订单信息";
            item3.isChecked = false
            item3.isOff = true
          });
          item2.children = orderInfos;
        } else {
          item2.children = [];
        }
      });
      item1.children = educationalInfos;
    } else {
      item1.children = [];
    }
  });
  return list;
}
