import request from "@/utils/request";

// 学生信息列表
export function querySuperStudents(data={},pagesize=20,current=1) {
    return request({
      url: `/sys/studentInfo/querySuperStudents?size=${pagesize}&page=${current}`,
      method: "post",
      data
    });
  }

  
// 批量删除学生
export function supderDeleteStudent(data={}) {
  return request({
    url: `/sys/studentInfo/supderDeleteStudent`,
    method: "post",
    data
  });
}

// 单个删除学生
export function supderDeleteEducationals(data={}) {
  return request({
    url: `/sys/educationalInfo/supderDeleteEducationals`,
    method: "post",
    data
  });
}

// 批量还原学生
export function supderActiveStudent(data={}) {
  return request({
    url: `sys/studentInfo/supderActiveStudent`,
    method: "post",
    data
  });
}


// 单个还原学生
export function supderActiveEducationals(data={}) {
  return request({
    url: `/sys/educationalInfo/supderActiveEducationals`,
    method: "post",
    data
  });
}






  
  
  