import request from "@/utils/request";

export function queryTask(page, size, data) {
  return request({
    url: "/sys/taskInfo/queryTasks?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addTask(data) {
  return request({
    url: "/sys/taskInfo/addTask",
    method: "post",
    data,
  });
}

export function getTask(id) {
  return request({
    url: "/sys/taskInfo/getOneTask/" + id,
    method: "get",
  });
}

export function getTaskDetail(id) {
  return request({
    url: "/sys/taskInfo/getTaskDetail/" + id,
    method: "get",
  });
}

export function updateTask(data) {
  return request({
    url: "/sys/taskInfo/updateTask",
    method: "post",
    data,
  });
}

export function updateTaskCjTime(data) {
  return request({
    url: "/sys/taskInfo/updateTaskTime",
    method: "post",
    data,
  });
}

export function deleteTask(data) {
  return request({
    url: "/sys/taskInfo/deleteTasks",
    method: "post",
    data,
  });
}

export function getTaskPeriodById(id) {
  return request({
    url: "/sys/taskTimePeriod/getTaskPeriodById/" + id,
    method: "get",
  });
}

export function addTaskTime(data) {
  return request({
    url: "/sys/taskTimePeriod/addTaskTime",
    method: "post",
    data,
  });
}

export function getTaskTime(id) {
  return request({
    url: "/sys/taskTimePeriod/getOneTaskTime/" + id,
    method: "get",
  });
}

export function updateTaskTime(data) {
  return request({
    url: "/sys/taskTimePeriod/updateTaskTime",
    method: "post",
    data,
  });
}

export function deleteTaskTime(data) {
  return request({
    url: "/sys/taskTimePeriod/deleteTaskTimes",
    method: "post",
    data,
  });
}

export function getTaskCode() {
  return request({
    url: "/sys/taskInfo/getTaskCode",
    method: "get",
  });
}

export function getTaskStatistics() {
  return request({
    url: "/sys/taskTimePeriod/getTaskStatistics",
    method: "get",
  });
}

export function batchSave(taskId, data) {
  return request({
    url: "/sys/taskTimePeriod/batchSave/" + taskId,
    method: "post",
    data,
  });
}

export function savePhotographer(data) {
  return request({
    url: "/sys/taskInfo/savePhotographer",
    method: "post",
    data,
  });
}

export function setTaskStatus(taskId, status) {
  return request({
    url: "/sys/taskInfo/setTaskStatus?taskId=" + taskId + "&status=" + status,
    method: "post",
  });
}

export function packageXp(data) {

  return request({
    url: "/sys/taskInfo/packageXpNew",
    method: "post",
    data,
  });
}
/**
 * 获取所有任务列表
 * @param {*} data
 * @returns
 */
export function queryAllTask() {
  return request({
    url: "/sys/taskInfo/queryAllTask",
    method: "get",
  });
}
/**
 * 添加任务标识
 * @param {*} data
 * @returns
 */
export function supderAddTask(data) {
  return request({
    url: "/sys/educationalInfo/supderAddTask",
    method: "post",
    data,
  });
}
/**
 * 清除任务标识
 * @param {*} data
 * @returns
 */
export function supderClearTask(data) {
  return request({
    url: "/sys/educationalInfo/supderClearTask",
    method: "post",
    data,
  });
}

// 退款详情列表
export function queryRefunds(currentPage, pageSize, data) {
  let taskId = data.taskId;
  // "/orderRefund/queryRefunds/"+taskId,
  return request({
    url:
      "/sys/orderRefund/queryRefunds/" +
      taskId +
      "?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",

    data,
  });
}

//确认已经退款
export function comfirmRefunds(data) {
    return request({
      url: "/sys/orderRefund/comfirmRefunds",
      method: "post",
      data,
    });
  }

  /**
 * 获取任务导入进度列表
 * @param {*} id//任务列表ID
 * @returns
 */
export function uploadTaskList(id) {
  return request({
    url: "/sys/uploadTask/list?taskId="+id,
    method: "post",
  });
}


/**
 * 采集提醒推送
 * @param {*} taskId//任务ID
 * @returns
 */
export function smsRemind(taskId) {
  return request({
    url: "/sys/taskInfo/smsRemind?taskId="+taskId,
    method: "get",
  });
}



/**
 * 上传成教
 *
 * @param {*} adultID//任务ID data//文件
 * @returns
 */
export function uploadAdult(adultID,data) {
  return request({
    url: "/sys/taskInfo/uploadAdult?id="+adultID,
    method: "post",
    data
  });
}