<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <transition name="appMain" mode="out-in">
        <router-view />
      </transition>
      <!-- 搜索部分 -->
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item">
          <label class="fn-14">学生姓名</label>
          <el-input
            placeholder="请输入学生姓名"
            autocomplete="off"
            v-model:value="search.name"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">身份证号</label>
          <el-input
            placeholder="请输入身份证号"
            autocomplete="off"
            v-model:value="search.idNumber"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            >查询
          </el-button>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="batchdelete"
            size="small"
            >删除
          </el-button>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="batchrestore"
            size="small"
            >还原
          </el-button>
        </div>
      </div>
      <!-- 列表内容 -->
      <div style="height: calc(100% - 60px)">
        <el-table
          v-loading="tableLoading"
          :data="permissionList"
          row-key="id"
          stripe
          border
          default-expand-all
          height="100%"
          style="width: 100%"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          @selection-change="handleSelectionChange"
        >
          <template #empty>
            <p>{{ tableStudentLoading == true ? "数据加载中" : "暂无数据" }}</p>
          </template>
          <el-table-column fixed type="selection" width="50" align="center">
            <template slot-scope="scope">
              <el-checkbox
                name="selection"
                v-model:checked="scope.row.isChecked"
                :disabled="scope.row.isOff"
                @change="selectableFirst(scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="typeName" label="学生信息" width="180">
            <template slot-scope="scope">
              {{ scope.row.typeName }}
              <!-- <el-tag v-if="scope.row.isImport == 1" size="small">导入</el-tag> -->
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="姓名" width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '学生信息'">{{
                execDecrypt(scope.row.name, "name")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="educationType" align="center" label="学历" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '学历信息'">{{
                scope.row.educationType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="idNumber" label="学校名称" width="160" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '学历信息'">{{
                scope.row.schoolName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="idNumber" label="学号" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '学历信息'">{{
                scope.row.studentCode
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="idNumber" label="身份证号" width="280" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '学生信息'">{{
                execDecrypt(scope.row.idNumber, "Idcard")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="订单编号" width="280" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.typeName == '订单信息'">{{ scope.row.orderNo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="订单状态" width="120" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.typeName == '订单信息'">
                <el-tag
                  v-if="scope.row.status === 0"
                  type="warning"
                  effect="plain"
                  size="small"
                  >下单</el-tag
                >
                <el-tag
                  v-else-if="scope.row.status === 1"
                  type="success"
                  effect="plain"
                  size="small"
                  >预付款
                </el-tag>
                <el-tag
                  v-else-if="scope.row.status === 2"
                  type=""
                  effect="plain"
                  size="small"
                  >完成</el-tag
                >
                <el-tag v-else type="danger" effect="plain" size="small">取消</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="260" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.typeName == '学历信息'">
                <el-button
                  v-if="scope.row.isImport == 0 && permission.adminDelAndRestTask"
                  type="text"
                  @click="handleTask(scope.row, 'add')"
                  >添加任务标识
                </el-button>
                <el-button
                  v-if="scope.row.isImport == 1 && permission.adminDelAndRestTask"
                  type="text"
                  @click="handleTask(scope.row, 'rest')"
                  >移除任务标识
                </el-button>
              </template>
              <el-button
                v-if="scope.row.isDel == 0 && permission.adminDelAndRest"
                type="text"
                @click="handleDelete(scope.row)"
                >删除
              </el-button>
              <el-button
                type="text"
                v-if="scope.row.isDel == 1 && permission.adminDelAndRest"
                @click="handleReduction(scope.row)"
                >还原
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="添加任务标识" :visible.sync="taskVisible" width="800px">
      <el-table :data="taskList" stripe border height="500px" style="width: 100%">
        <el-table-column prop="title" label="任务名称"> </el-table-column>
        <el-table-column prop="collectType" label="采集类型" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.collectType === 0 ? "线下校拍" : "线下散拍" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="beginDate"
          :formatter="columnDateFormat"
          label="开始日期"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束日期"
          :formatter="columnDateFormat"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="任务状态" width="80" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="info" effect="plain" size="small"
              >未开始</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              size="small"
              effect="plain"
              >预约中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 2"
              type="warning"
              size="small"
              effect="plain"
              >已结束</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="任务代码" width="140" align="center">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="permission.adminDelAndRestTask"
              type="text"
              @click="handleUpdate(scope.row.id)"
              >设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  querySuperStudents,
  supderDeleteStudent,
  supderActiveStudent,
  supderActiveEducationals,
  supderDeleteEducationals,
} from "@/api/administration";
import { queryPageButton } from "@/api/permission";
import { queryAllTask, supderAddTask, supderClearTask } from "@/api/task";
import { dataToTree } from "@/utils/list-to-tree";
import { dateFormat } from "@/utils/date";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";

export default {
  name: "administration",
  data() {
    return {
      tableLoading: false,
      tableStudentLoading: false,
      permissionList: [],
      tableSelection: [],
      studentIds: [],
      eduIds: [],
      search: {
        idNumber: "",
        name: "",
      },
      permission: {
        adminDelAndRest: false,
        adminDelAndRestTask: false,
      },
      taskVisible: false,
      taskList: [],
      eduId: null,
    };
  },
  created() {
    this.query();
    //获取权限按钮
    const data = 131;
    queryPageButton(data).then((resp) => {
      if (resp.code == 0) {
        for (const button of resp.data) {
          if (button.code === "adminDelAndRest") {
            this.permission.adminDelAndRest = true;
          }
          if (button.code === "adminDelAndRestTask") {
            this.permission.adminDelAndRestTask = true;
          }
        }
      }
    });
  },
  methods: {
    query() {
      // this.deleteButton = true;
      let params = {
        idNumber: this.search.idNumber,
        name: this.search.name,
      };
      this.tableStudentLoading = true;
      querySuperStudents(params).then((resp) => {
        if (resp.code === "0") {
          this.permissionList = dataToTree(resp.data.content);
          this.tableLoading = false;
        }
      });
    },
    selectableFirst(obj) {
      let that = this;
      let studentIds = JSON.parse(JSON.stringify(this.studentIds));
      let eduIds = JSON.parse(JSON.stringify(this.eduIds));
      if (obj.studentId) {
        if (obj.isChecked) {
          let temp = eduIds.filter((o) => o == obj.id);
          if (!temp.length) {
            eduIds.push(obj.id);
            that.eduIds = eduIds;
          }
        } else {
          let arr = [];
          eduIds.map((t) => {
            if (t != obj.id) {
              arr.push(t);
            }
          });
          that.eduIds = arr;
        }
      } else {
        if (obj.isChecked) {
          let temp = studentIds.filter((o) => o == obj.id);
          if (!temp.length) {
            studentIds.push(obj.id);
            that.studentIds = studentIds;
          }
        } else {
          let arr = [];
          studentIds.map((t) => {
            if (t != obj.id) {
              arr.push(t);
            }
          });
          that.studentIds = arr;
        }
      }
    },
    handleSearch() {
      this.query();
    },
    // 批量删除
    batchdelete() {
      let studentIds = JSON.parse(JSON.stringify(this.studentIds));
      let eduIds = JSON.parse(JSON.stringify(this.eduIds));
      if (!eduIds.length && !studentIds.length) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
      } else {
        if (this.tableLoading) {
          return false;
        }
        this.tableLoading = true;
        if (studentIds.length) {
          supderDeleteStudent(studentIds).then((resp) => {
            this.tableLoading = false;
          });
        }
        if (eduIds.length) {
          supderDeleteEducationals(eduIds).then((resp) => {
            this.tableLoading = false;
          });
        }

        setTimeout(() => {
          this.$notify({
            title: "温馨提示",
            message: "删除成功",
            type: "success",
          });
          this.query();
        }, 1000);
      }
    },
    // 批量还原
    batchrestore() {
      let studentIds = JSON.parse(JSON.stringify(this.studentIds));
      let eduIds = JSON.parse(JSON.stringify(this.eduIds));
      if (!eduIds.length && !studentIds.length) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
      } else {
        if (this.tableLoading) {
          return false;
        }
        this.tableLoading = true;
        if (studentIds.length) {
          supderActiveStudent(studentIds).then((resp) => {
            // console.log(resp);
            this.tableLoading = false;
          });
        }
        if (eduIds.length) {
          supderActiveEducationals(eduIds).then((resp) => {
            this.tableLoading = false;
          });
        }
        setTimeout(() => {
          this.$notify({
            title: "温馨提示",
            message: "还原成功",
            type: "success",
          });
          this.query();
        }, 1000);
      }
    },
    // 单个删除数据
    handleDelete(datas) {
      //学历
      if (datas.studentId) {
        supderDeleteEducationals([datas.id]).then((resp) => {
          // console.log(resp);
          this.tableLoading = false;
          if (resp.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.message,
              type: "success",
            });
            this.query();
          }
        });
      } else {
        // 学生
        supderDeleteStudent([datas.id]).then((resp) => {
          // console.log(resp);
          this.tableLoading = false;
          if (resp.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.message,
              type: "success",
            });
            this.query();
          }
        });
      }
    },

    // 单个还原数据
    handleReduction(datas) {
      if (datas.studentId) {
        supderActiveEducationals([datas.id]).then((resp) => {
          // console.log(resp);
          this.tableLoading = false;
          if (resp.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.message,
              type: "success",
            });
            this.query();
          }
        });
      } else {
        // 学生
        supderActiveStudent([datas.id]).then((resp) => {
          // console.log(resp);
          this.tableLoading = false;
          if (resp.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.message,
              type: "success",
            });
            this.query();
          }
        });
      }
    },
    // 选择框
    handleSelectionChange(val) {
      let that = this;
      if (val.length) {
        that.permissionList = dataToTree(that.permissionList, true);
        that.permissionList.map((item) => {
          that.studentIds.push(item.id);
          if (item.educationalInfos.length) {
            item.educationalInfos.map((it) => {
              that.eduIds.push(it.id);
            });
          }
        });
      } else {
        that.permissionList = dataToTree(that.permissionList, false);
        that.studentIds = [];
        that.eduIds = [];
      }
    },
    /**
     * 添加/清除任务标识
     * @param {*} datas
     * @param {*} mod
     */
    handleTask(datas, mod = "add") {
      if (mod == "add") {
        this.getTaskAll();
        this.eduId = datas.id;
        this.taskVisible = true;
      } else {
        this.$confirm("您是否执行此操作？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          supderClearTask([datas.id]).then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "设置成功",
                message: res.message,
                type: "success",
              });
              this.taskVisible = false;
              this.query();
              return;
            } else {
              this.$notify({
                title: "警告",
                message: res.message,
                type: "warning",
              });
              return;
            }
          });
        });
      }
    },
    /**
     * 获取任务
     */
    getTaskAll() {
      queryAllTask().then((res) => {
        if (res.code == 0) {
          this.taskList = res.data;
        }
      });
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    /**
     * 添加任务标识
     */
    handleUpdate(id) {
      let params = {
        id: this.eduId,
        taskId: id,
      };
      this.$confirm("您是否执行此操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        supderAddTask(params).then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "设置成功",
              message: res.message,
              type: "success",
            });
            this.taskVisible = false;
            this.query();
            return;
          } else {
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning",
            });
            return;
          }
        });
      });
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },

  mounted() {},
};
</script>

<style>
.card {
  height: calc(100% - 2px);
}

.cardBody {
  height: calc(100% - 40px);
}

.appMain-leave-active,
.appMain-enter-active {
  transition: all 0.5s;
}

.appMain-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.appMain-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
